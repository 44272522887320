@font-face {
  font-family: "Nunito";
  src: url("./../assets/fonts/Nunito-Regular.ttf");
}
body{
  margin: 0px;
  font-family: 'Nunito';
}
*,*::before,*::after{
  box-sizing: border-box;
}