.topBar{
  z-index: 100;
  display: flex;
  position: fixed;
  width: 100vw;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  background-color: #1f1f1f;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
  a, a:visited{
    color: white;
    text-decoration: none;
  }
  .topBarSection{
    padding: 12px 17px 12px 17px;
    font-size: 23px;
    display: flex;
    align-items: center;
    opacity: 0.8;
    cursor: pointer;

    transition: opacity 0.2s ease-in,
                background-color 0.2s ease-in;
    &:hover{
      opacity: 1;
      background-color: #216ab8;
      text-decoration: underline;
    }
  }
}
@media only screen and (max-width: 600px) {
  .topBar{
    padding: 0 5px 0 5px;
    .topBarSection{
      padding: 12px;
      font-size: 16px;
    }
  }
}