.app{
  width: 100vw;
  min-height: 100vh;
  color: white;
  display: flex;
  justify-content: center;
  background-image: url('./../assets/bck.jpg');
  background-position: center;
  background-size: cover;
  .content{
    position: relative;
    padding: 70px 20px 15px 20px;
    width: 350px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(31, 117, 209, 0.9);
    .title{
      // text-align: center;
      position: relative;
      z-index: 10;
      font-weight: bold;
      font-size: 35px;
      .mainTitle{
        font-size: 1.1em;
      }
      .subTitle{
        font-size: 0.8em;;
      }
    }
  }
  .items{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .item{
    width: 100%;
    max-width: 300px;
    min-height: 100px;
    padding: 10px;
    border-radius: 5px;
    background-color: #f04e43;
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.5);
    margin-top: 15px;
    cursor: pointer;
    transition: 0.2s opacity ease-in;
    .itemTitle{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      .text{
        font-size: 25px;
        font-weight: bold;
        width: calc(100% - 90px);
      }
      .date{
        font-size: 15px;
        width: 90px;
        text-decoration: underline;
      }
    }
    .descContainer{
      display: flex;
      flex-direction: row;
      .description{
        width: calc(100% - 30px);
      }
      .checkBox{
        width: 24px;
        .checkBoxContainer{
          width: 24px;
          height: 24px;
          font-size: 15px;
          border: 2px solid rgb(255, 255, 255);
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .badge{
    width: 100%;
    max-width: 250px;
  }
  .author{
    position: absolute;
    left: 0px;
    bottom: 0px;
    font-size: 18px;
    opacity: 0.6;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.377);
    border-radius: 5px;
    margin: 5px;

    transition: 0.2s ease-in opacity;
    a, a:visited{
      font-weight: bold;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.6);
      transition: 0.2s ease-in color;
    }

    &:hover{
      opacity: 1;
      a{
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .app{
    .content{
      padding: 50px 11px 0px 11px;
      width: 100%;
      .item{
        .text{
          font-size: 23px;
        }
        .date{
          font-size: 13px;
        }
      }
    }
  }
}



